<template>
	<div class="w-100">
		<AutoCompleteInput
			hide-details
			id="terms-and-conditions-id"
			:items="termsAndConditions"
			:disabled="pageLoading"
			:loading="pageLoading"
			:placeholder="placeholder"
			item-value="id"
			item-text="title"
			:rules="rules"
			:append-outer-icon="appendOuterIcon"
			v-model="terms_and_condition_id"
			v-on:click:append-outer="createDialog = true"
			v-on:change="selectTermsText($event)"
		>
		</AutoCompleteInput>
		<div class="py-0">
			<TextAreaInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="terms-and-conditions-text"
				:placeholder="`${placeholder} description`"
				v-model="terms_and_conditions_text"
				v-on:blur="emitData($event)"
			>
			</TextAreaInput>
		</div>
		<Dialog :dialog="createDialog" :dialogWidth="dialogWidth" v-if="createDialog">
			<template v-slot:title>
				<v-layout>
					<v-flex> Manage Terms & Conditions </v-flex>
					<v-flex class="text-right">
						<v-btn
							tile
							depressed
							color="blue darken-4 text-white"
							small
							class="mx-2"
							v-on:click="createUnitType"
						>
							<span class="font-size-14 font-weight-600"> <v-icon>mdi-plus</v-icon> Add</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<v-container class="py-0">
					<v-form
						ref="unitForm"
						v-model="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateUnitSetting"
					>
						<v-row class="py-0">
							<v-col md="12" class="py-0">
								<v-simple-table>
									<tbody>
										<template v-for="(type, index) in termsAndConditionList">
											<tr :key="`title-row-${index}`" class="table-title-row">
												<td style="border: none" width="95%">
													<TextInput
														hide-details
														:disabled="pageLoading"
														placeholder="Enter terms & condition"
														:loading="pageLoading"
														:id="`title-${index}`"
														v-model="type.title"
														class="mb-3"
														:class="{
															required: !type.title,
														}"
														:rules="[vrules.required(type.title, 'Title')]"
													></TextInput>
													<TextAreaInput
														:rows="2"
														hide-details
														placeholder="Description"
														v-model="type.terms_and_conditions"
														:disabled="pageLoading"
														:loading="pageLoading"
														class="mb-3"
													></TextAreaInput>
												</td>
												<td style="border: none" width="5%">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																icon
																fab
																min-width="30"
																width="30"
																:disabled="termsAndConditionList.length == 1 ? true : false"
																v-on:click="deleteUnitType(index, type)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon color="deep-orange">mdi-delete </v-icon>
															</v-btn>
														</template>
														<span>Click here to delete</span>
													</v-tooltip>
												</td>
											</tr>
											<tr :key="`unit-index-${index}`" v-if="false">
												<td colspan="2" style="border: none; height: 15px !important"></td>
											</tr>
										</template>
									</tbody>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="closeDialog()"
				>
					Close
				</v-btn>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					:loading="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="updateUnitSetting"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Terms and Condition"
			:delete-text="deleteText"
			v-on:success="removeUnit"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger, find } from "lodash";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { SET_TERMS_AND_CONDITION } from "@/core/services/store/config.module";

export default {
	name: "SelectTermsAndConditions",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Object, Number, String, Array],
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		contentClass: {
			type: Object,
			default: null,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		reverse: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		inputCol: {
			type: Number,
			default: 9,
		},
		labelCol: {
			type: Number,
			default: 3,
		},
		type: {
			type: String,
			default: null,
		},
	},
	data: () => {
		return {
			terms_and_condition_id: null,
			terms_and_conditions_text: null,
			createDialog: false,
			formValid: true,
			pageLoading: false,
			termsAndConditionList: [],
			deleteURL: null,
			deleteId: null,
			deleteDialog: false,
			deleteIndex: null,
			deleteText: null,
			autocompleteinput: null,
			menuProps: {},
		};
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.terms_and_condition_id = param && param.id;
				this.terms_and_conditions_text = param && param.description;
			},
		},
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
		selectTermsText(id) {
			const _text = find(this.termsAndConditionList, (row) => row.id == id);
			if (_text) {
				this.terms_and_conditions_text = _text.terms_and_conditions;
			}
			this.$emit("change", {
				id: this.terms_and_condition_id,
				description: this.terms_and_conditions_text,
			});
		},
		emitData(description) {
			this.$emit("change", {
				id: this.terms_and_condition_id,
				description: description,
			});
		},
		updateUnitSetting() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.unitForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.unitForm.validate()) {
				return false;
			}

			_this.pageLoading = true;

			ApiService.setHeader();
			ApiService.post(`termconditions`, { terms_and_conditions: _this.termsAndConditionList })
				.then(({ data }) => {
					_this.$store.dispatch(SET_TERMS_AND_CONDITION, this.type).then((data) => {
						this.termsAndConditionList = data;
					});
					_this.createDialog = false;
					_this.$emit("success", data);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.createDialog = false;
					_this.pageLoading = false;
				});
		},
		createUnitType() {
			this.termsAndConditionList.push({
				id: null,
				uuid: null,
				title: null,
				terms_and_conditions: null,
				module: this.type,
			});

			// this.$nextTick(() => {
			// 	const focus_element = `unit-${this.termsAndConditionList.length - 1}`;
			// 	document.getElementById(focus_element).focus();
			// });
		},
		deleteUnitType(index /* , data */) {
			/* if (this.termsAndConditionList.length > 1) {
				if (data && data.uuid) {
					this.deleteDialog = true;
					this.deleteId = data.id;
					this.deleteIndex = index;
					this.deleteText = data.title;
					this.deleteURL = "termconditions/" + data.uuid;
				} else {
					this.termsAndConditionList.splice(index, 1);
				}
			} */
			if (this.termsAndConditionList.length > 1) {
				this.termsAndConditionList.splice(index, 1);
			}
		},
		removeUnit() {
			if (this.deleteIndex >= 0) {
				this.termsAndConditionList.splice(this.deleteIndex, 1);
				this.deleteIndex = null;
				this.deleteDialog = false;
				this.$store
					.dispatch(SET_TERMS_AND_CONDITION, this.type)
					.then((data) => {
						this.termsAndConditionList = data;
						this.$emit("success", this.termsAndConditionList);
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.deleteDialog = false;
					});
				console.log(this.deleteDialog);
			}
		},
		closeDialog() {
			this.createDialog = false;
			const _list = [...this.termsAndConditionList];
			this.termsAndConditionList = _list.filter((row) => {
				return row.id;
			});
		},
	},
	components: {
		Dialog,
		DeleteTemplate,
		TextInput,
		TextAreaInput,
		AutoCompleteInput,
	},
	mounted() {
		const _this = this;
		// _this.termsAndConditionList = _this.termsAndConditions;
		_this.$store.dispatch(SET_TERMS_AND_CONDITION, _this.type).then((data) => {
			_this.termsAndConditionList = data;
		});
	},
	computed: {
		...mapGetters(["errors", "termsAndConditions"]),
		dialogWidth() {
			if (document.body.clientWidth > 992) {
				return 800;
			}
			return toSafeInteger((document.body.clientWidth / 100) * 90);
		},
	},
};
</script>
