<template>
	<div class="w-100 po-line-items">
		<table width="100%">
			<thead>
				<tr>
					<th class="pa-2" width="40"></th>
					<th class="pa-2 text-center" width="40">#</th>
					<th class="pa-2" width="300">Product</th>
					<th class="pa-2" width="300">Description</th>
					<th class="pa-2" width="150">UOM</th>
					<th class="pa-2" width="120">Qty</th>
					<th class="pa-2 text-right" width="120">Rate</th>
					<th class="pa-2 text-right" width="120">Amount</th>
					<th class="pa-2 text-right" width="40"></th>
				</tr>
			</thead>
			<!-- <tbody > -->
			<Draggable
				tag="tbody"
				v-model="line_items"
				class="draggable-group"
				handle=".draggable-drag-icon"
				v-clickoutside="outsideClicked"
				v-on:change="updateItemOrder($event)"
			>
				<tr v-for="(row, index) in line_items" :key="index" class="">
					<td class="py-1 px-2">
						<v-icon class="draggable-drag-icon cursor-move" color="blue darken-4">mdi-drag</v-icon>
					</td>
					<td class="py-1 px-2 text-center">{{ index + 1 }}</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<template class="px-1">
							{{row.product_name?row.product_name:null }}
							<span v-if="row.is_pending && (row.is_pending == true)">
								<v-chip
									x-small
									label
									color="red"
									text-color="white"
								>
									<span class="fw-500">RPO </span>
								</v-chip>
							</span>
						</template>
						
						<v-autocomplete
							v-if="row.isEditable"
							outlined
							hide-details
							:items="productList"
							:disabled="pageLoading"
							:loading="pageLoading"
							id="Product"
							placeholder="Product"
							v-model="row.product"
							class="mt-0"
							append-outer-icon="mdi-plus"
							@click:append-outer="openCreateProduct()"
							v-on:change="getProductPrice(row.product, index)"
						>
							<template v-slot:item="data">
								<template>
									<v-list-item-content dense class="text-truncate">
										<v-list-item-title v-html="data.item.text"></v-list-item-title>
									</v-list-item-content>
									<v-list-item-icon dense class="my-2 pl-2 pr-0" v-if="false">
										<v-chip
											:class="`px-1 white--text ${data.item.product_type == 'inventory' ? 'orange' : 'blue'}`"
											label
											small
										>
											<span class="text-capitalize">
												{{ data.item.product_type == "inventory" ? "product" : data.item.product_type }}
											</span>
										</v-chip>
									</v-list-item-icon>
								</template>
							</template>
						</v-autocomplete>
						<template v-else>{{ getProductName(row.product) }}</template>
					</td>
					<td
						style="max-width: 300px"
						class="py-1 px-2 text-truncate"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<TextInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model="row.description"
							class="mt-0"
						></TextInput>
						<template v-else>{{ row.description }}</template>
					</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<SelectUnit
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							id="uom"
							hide-top-margin
							placeholder="uom"
							class="mt-0"
							v-model="row.uom"
							v-on:change="changeClicked()"
						></SelectUnit>
						<template v-else>{{ row.uom }}</template>
					</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<QuantityInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model="row.quantity"
							class="mt-0 px-0 text-right"
							type="number"
							hide-spin-buttons
							v-on:keyup="getProductTotal()"
						></QuantityInput>
						<template v-else>{{ row.quantity }}</template>
					</td>
					<td
						class="py-1 px-2 text-right"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<PriceInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model.number="row.rate"
							reverse
							class="mt-0"
							type="number"
							hide-spin-buttons
							v-on:keyup="getProductTotal()"
							v-on:blur="fixedOnBlur()"
							v-on:keydown.tab.prevent="tabClicked($event, index)"
						></PriceInput>
						<template v-else>{{ formatMoney(row.rate) }}</template>
					</td>
					<td
						class="py-1 px-2 text-right"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<!-- <PriceInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model.number="row.total"
							reverse
							outlined
							append-icon="mdi-currency-usd"
							class="mt-0"
							type="number"
							hide-spin-buttons
							v-on:keyup="updateTotal()"
							v-on:blur="fixedOnBlur()"
							v-on:keydown.tab.prevent="tabClicked($event, index)"
						></PriceInput> -->
						<template>{{ formatMoney(row.total) }}</template>
					</td>
					<td class="py-1 px-2 text-right">
						<v-icon
							color="red"
							:disabled="line_items.length == 1 ? true : false"
							class="cursor-pointer"
							@click="deleteLineItems(index, row.id)"
							>mdi-delete-outline</v-icon
						>
					</td>
				</tr>
			</Draggable>
			<!-- </tbody> -->
			<tfoot>
				<tr>
					<td class="pa-2 borer-none" colspan="4">
						<v-btn @click="addLineItems()" class="white--text" depressed color="blue darken-4" tile>
							<v-icon left>mdi-plus</v-icon>
							Add Line item
						</v-btn>
					</td>
					<td class="pa-2 borer-none text-right" colspan="3"><b>Sub Total</b></td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(getSubTotal()) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr>
					<td class="pa-2 borer-none text-right" colspan="7">
						<div class="d-flex align-items-center justify-content-end">
							<b>Discount</b>
							<div style="max-width: 80px" class="ml-2">
								<QuantityInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="discount_value"
									type="number"
									class="mt-0"
									v-on:blur="getDiscount()"
									v-on:change="getDiscount()"
								></QuantityInput>
							</div>
							<div style="max-width: 60px" class="">
								<AutoCompleteInput
									hide-details
									:items="discountTypeList"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="discount_value_type"
									class="mt-0 text-center"
									v-on:change="getDiscount()"
								></AutoCompleteInput>
							</div>
						</div>
					</td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(this.discount_amount) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr>
					<td class="pa-2 borer-none text-right" colspan="7">
						<div class="d-flex align-items-center justify-content-end">
							<v-checkbox
								hdie-details
								v-model="tax_applied"
								class="ma-0 pt-0"
								hide-details
								small
								id="is-tax"
								v-on:change="getTax()"
								dense
								color="blue darken-4"
							></v-checkbox>
							<b>Tax</b>
							<div style="max-width: 60px" class="ml-2">
								<span class="fw-600" style="font-size: 16px"> {{ tax_value }} % </span>
								<AutoCompleteInput
									v-if="false"
									hide-details
									:items="taxList"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="tax_value"
									class="mt-0 text-center"
								></AutoCompleteInput>
							</div>
						</div>
					</td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(getTax()) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr>
					<td class="pa-2 borer-none text-right" colspan="7">
						<div class="d-flex align-items-center justify-content-end">
							<b>Adjustment</b>
							<div style="max-width: 60px" class="ml-2">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									type="number"
									v-on:blur="getAdjustmentAmount()"
									v-on:change="getAdjustmentAmount()"
									v-model="adjustment"
									class="mt-0"
								></TextInput>
							</div>
						</div>
					</td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(adjustment_amount) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr>
					<td class="pa-2 borer-none text-right" colspan="7">
						<b style="font-size: 16px">Grand Total</b>
					</td>
					<td class="pa-2 borer-none text-right" width="180">
						<b style="font-size: 16px">{{ formatMoney(getGrandTotal()) }}</b>
					</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
			</tfoot>
		</table>
		<Dialog
			:dialog="deleteConfirmationDialog"
			v-on:close="deleteConfirmationDialog = false"
			v-if="deleteConfirmationDialog"
			:dialog-width="500"
		>
			<template v-slot:title> Are you sure? </template>
			<template v-slot:body>
				<v-row>
					<v-col md="12" class="py-0">
						<p class="mt-0" style="line-height: 18px">
							Are you sure want to delete line item?
							<br />
							Line item can not be recovered once deleted?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="deleteConfirmationLoading"
					depressed
					tile
					@click="deleteConfirmationDialog = false"
				>
					NO</v-btn
				>
				<v-btn
					:disabled="deleteConfirmationLoading"
					:loading="deleteConfirmationLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click="yesDeleteLineItem()"
				>
					YES
				</v-btn>
			</template>
		</Dialog>
		<ProductCreateDialog
			v-if="createProductDialog"
			:dialog="createProductDialog"
			:supplier-id="supplier"
			v-on:close="createProductDialog = false"
			v-on:success="refreshProducts()"
		></ProductCreateDialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import SelectUnit from "@/view/components/SelectUnit";
import PriceInput from "@/view/components/PriceInput";
import ProductCreateDialog from "@/view/components/ProductCreateDialog";
import Dialog from "@/view/components/Dialog";
import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import Draggable from "vuedraggable";
import { GetAllProduct } from "@/core/lib/common.lib";
import { DeletePOLineItem, RPOLineItems } from "@/core/lib/purchase-order.lib";
import { cloneDeep, filter, find, map } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import { saveData, deleteData } from "@/core/services/local.service";
import "@/core/event-bus/click-outside";
import { SET_LINEITEMS } from "@/core/services/store/lineitem.module";
export default {
	name: "po-line-items",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		supplier: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		value: {
			handler(param) {
				this.line_items = param;
			},
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			tax_applied: true,
			pageLoading: false,
			deleteConfirmationDialog: false,
			deleteConfirmationLoading: false,
			createProductDialog: false,
			deleteId: null,
			deleteIndex: -1,
			isOutside: true,
			rpo_uuid:null,
			line_items: [
				{
					isEditable: false,
					product: null,
					description: null,
					quantity: 1,
					order: 1,
					uom: null,
					rate: 0,
					total: 0,
				},
			],
			taxList: [8],
			tax_value: 8,
			taxable_amount: 0,
			tax_amount: 0,
			discount_value: 0,
			discount_amount: 0,
			discount_value_type: 1,
			discount_type: 1,
			adjustment: 0,
			adjustment_amount: 0,
			sub_total: 0,
			total: 0,
			discountTypeList: [
				{ text: "$", value: 1 },
				{ text: "%", value: 2 },
			],
			productList: [],
			allProductList: [],
		};
	},
	methods: {
		/* getNewProductList(data) {
			console.log(data, "row data");
			let newProductList = this.productList ? cloneDeep(this.productList) : [];
			if (data.original_product) {
				let productData = data.original_product;
				console.log(productData, "productData");
				newProductList.push({
					id: productData.id,
					name: productData.name,
					unit_price: productData.unit_price,
					unit: productData.unit,
					selling_cost: productData.selling_cost,
					discount_price: productData.discount_price,
					charges: productData.discount_price,
					product_type: productData.product_type,
					barcode: productData.barcode,
					uuid: productData.uuid,
					description: productData.description,
					pid: productData.pid,
					uprice: productData.uprice,
					sprice: productData.sprice,
					dprice: productData.dprice,

					suppliers: [
						{
							id: 0,
							price: 0,
						},
					],
					uom: productData.uom,
					value: productData.id,
					text: `${productData.barcode} - ${productData.name}`,
					//text: "P024 - fdgdfgh",
					seller_id: 0,
					seller_price: 0,
				});
			}
			return newProductList;
		},
 */

		/* methods12(data, index) {
			let lineItemData = this.line_items;
			let order = lineItemData.length;
			lineItemData[index] = {
				isEditable: false,
				id: null,
				uuid: data.uuid,
				product: data.id,
				description: data.description,
				uom: null,
				quantity: 1,
				pendingstatus: true,  
				rate: 0,
				total: 0,
				order: order + 1,
				original_product: data,
			};
			console.log(lineItemData, "lineItemData");
			this.line_items = lineItemData;


		}, */
		outsideClicked() {
			if (!this.isOutside) {
				const _line_items = this.line_items.map((row) => {
					return { ...row, isEditable: false };
				});
				this.line_items = _line_items;
				this.isOutside = true;
			}
		},
		changeClicked() {
			
			this.isOutside = true;
			//console.log("changeClicked");
		},
		updateItemOrder() {
			// const old_index = param.move.oldIndex;
			// const new_index = param.move.newIndex;
			// console.log(param);
			// console.log(this.line_items);
		},
		editRow(event, index) {
			this.isOutside = false;
			const element = event.target;
			const _line_items = this.line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index].isEditable = true;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}
		},
		tabClicked(event, index) {
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}

			const _line_items = this.line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index + 1].isEditable = true;
			this.$nextTick(() => {
				// const inputarea = element.querySelector("input");
				// if (inputarea) {
				// 	inputarea.focus();
				// 	inputarea.select();
				// }
			});
		},
		async yesDeleteLineItem() {
			try {
				this.deleteConfirmationLoading = true;
				if (this.deleteId) {
					await DeletePOLineItem(this.deleteId);
					this.line_items.splice(this.deleteIndex, 1);
					this.$emit("input", this.line_items);

					this.deleteId = null;
					this.deleteIndex = -1;
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.deleteConfirmationLoading = false;
				this.deleteConfirmationDialog = false;
			}
		},
		async deleteLineItems(index, id) {
			if (!this.$route.query?.rpuuid) 
			{
				if (id) {
					this.deleteConfirmationDialog = true;
					this.deleteId = id;
					this.deleteIndex = index;
				} else {
					if (this.line_items.length > 1) {
						this.line_items.splice(index, 1);
						this.$emit("input", this.line_items);
					}
					if (id) {
						await DeletePOLineItem(id);
					}
				}
			} else {
				if (this.line_items.length > 1) {
					this.line_items.splice(index, 1);
					this.$emit("input", this.line_items);
				}
			}
			// if (this.line_items.length > 1) {
			// 	this.line_items.splice(index, 1);
			// 	this.$emit("input", this.line_items);
			// }
			this.saveToLocalLineItem();
		},
		addLineItems() {
			
			const _order = this.line_items.length;
			this.line_items.push({
				isEditable: false,
				id: null,
				uuid: null,
				product: null,
				description: null,
				//pendingstatus: false,
				uom: null,
				quantity: 1,
				rate: 0,
				total: 0,
				order: _order + 1,
			});
			this.$emit("input", this.line_items);
		},
		updateCalculation() {
			this.$emit("update:total", {
				tax_value: this.tax_value,
				taxable_amount: this.taxable_amount,
				tax_amount: this.tax_amount,
				discount_value: this.discount_value,
				discount_value_type: this.discount_value_type,
				tax_applied: this.tax_applied ? 1 : 0,
				discount_amount: this.discount_amount,
				discount_type: this.discount_type,
				adjustment: this.adjustment,
				adjustment_amount: this.adjustment_amount,
				sub_total: this.sub_total,
				total: this.total,
			});
			this.$emit("input", this.line_items);
			this.saveToLocalLineItem();
		},
		getProductName(id) {
			/* if (data.pendingstatus && data.pendingstatus == true) {
				if(data.original_product)
				{
					return `${data.original_product.barcode} - ${data.original_product.name}`
				}
				return null;
			} else {
				const product = find(this.productList, (row) => row.value == data.product);
				console.log(product, "product444");
				if (product) {
					return product.text;
				} else {
					return null;
				}
			} */

			const product = find(this.productList, (row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},
		validateNumber(value) {
			const pattern = /^(\d+(?:,\d{1,2})?).*/;
			return pattern.test(value);
		},
		getDiscount() {
			if (this.discount_value != 0) {
				if (!this.validateNumber(this.discount_value)) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
			}
			const subtotal = this.sub_total;
			if (this.discount_value_type == 1) {
				//$
				if (Number(this.discount_value) > Number(subtotal)) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
				this.discount_amount = Number(this.discount_value);
			} else if (this.discount_value_type == 2) {
				//%
				if (Number(this.discount_value) > 100) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
				this.discount_amount = Number((subtotal * this.discount_value) / 100);
			}
			return this.discount_amount;
		},
		getTax() {
			if (this.tax_applied) {
				const _taxable_amount = Number(this.sub_total) - Number(this.discount_amount);
				this.tax_amount = Number((_taxable_amount * this.tax_value) / 100);
				this.taxable_amount = _taxable_amount;
			} else {
				this.tax_amount = 0;
			}
			return this.tax_amount;
		},
		getAdjustmentAmount() {
			this.adjustment_amount = Number(this.adjustment);
		},
		getGrandTotal() {
			this.getDiscount();
			this.total =
				Number(this.sub_total) +
				Number(this.tax_amount) +
				Number(this.adjustment) -
				Number(this.discount_amount);
			this.updateCalculation();
			return this.total;
		},
		fixedOnBlur() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					rate: Number(row.rate),
					total: Number(row.total),
				};
			});
			this.line_items = _line_item;
		},
		getProductTotal() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					total: this.to_fixed(row.quantity * row.rate),
				};
			});
			this.line_items = _line_item;
		},
		updateTotal() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					rate: row.total / row.quantity,
				};
			});
			this.line_items = _line_item;
		},
		getProductPrice(id, index) {
			const _product = find(this.productList, (row) => row.id == id);
			if (_product && _product.product_type == "inventory") {
				this.line_items[index].rate = _product.seller_price;
				this.line_items[index].total = _product.seller_price;
				this.line_items[index].description = _product.description;
				this.line_items[index].uom = _product.unit ? _product.unit : null;
			}
			if (_product && _product.product_type == "service") {
				this.line_items[index].rate = _product.charges;
				this.line_items[index].total = _product.charges;
				this.line_items[index].description = _product.description;
				this.line_items[index].uom = _product.unit ? _product.unit : null;
			}
			this.changeClicked();
			this.saveToLocalLineItem();
		},
		getAllProduct() {
			GetAllProduct()
				.then(({ list }) => {
					const _product_data = filter(list, (row) => {
						return row.product_type == "inventory";
					});
					this.allProductList = map(_product_data, (row) => {
						return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					});
				})
				.catch((error) => {
					console.log(error);
				});
			if (this.calculations) {
				//console.log(this.calculations, "calculations");
			}
		},
		/* This method call from the parent components */
		getRelatedProduct(id) {
			if (id) {
				if (!this.$route.query?.rpuuid) {
					const _product_list = filter(this.all_products, (row) => {
						const exist = find(row["suppliers"], { id: id });
						return exist ? true : false;
					});
					this.productList = map(_product_list, (row) => {
						const exist = find(row["suppliers"], (row) => {
							return row.id == id;
						});
						if (exist) {
							return { ...row, seller_id: exist.id, seller_price: exist.price };
						}
					});
				} else {
					this.productList = map(this.all_products, (row) => {
						const exist = find(row["suppliers"], (row) => {
							return row.id == id;
						});
						if (exist) {
							return { ...row, seller_id: exist.id, seller_price: exist.price };
						} else {
							return { ...row, seller_price: 0 };
						}
					});
				}
			} else {
				this.productList = map(this.all_products, (row) => {
					const exist = find(row["suppliers"], (row) => {
						return row.id == id;
					});
					if (exist) {
						return { ...row, seller_id: exist.id, seller_price: exist.price };
					} else {
						return { ...row, seller_price: 0 };
					}
				});
				this.line_items = filter(this.line_items, (row) => {
					const exist = find(this.productList, { id: row.product });
					return exist ? true : false;
				});
				// this.line_items = [];
			}
			
			//this.addLineItems();
			deleteData("po_line_items");
		},
		openCreateProduct() {
			//this.index = index;
			this.createProductDialog = true;
		},
		saveToLocalLineItem() {
			const items = map(this.line_items, (row) => {
				return {
					id: row.id ? row.id : null,
					isEditable: row.isEditable ? row.isEditable : false,
					uuid: row.uuid ? row.uuid : null,
					product: row.product ? row.product : null,
					description: row.description ? row.description : null,
					quantity: row.quantity ? Number(row.quantity) : 0,
					rate: row.rate ? Number(row.rate) : 0,
					uom: row.uom ? row.uom : null,
					tax_value: row.tax_value ? row.tax_value : 0,
					tax_amount: row.tax_amount ? row.tax_amount : 0,
					sub_total: row.total ? Number(row.total) : 0,
					total: row.total ? Number(row.total) : 0,
					order: Number(row.order),
					product_type: row.product_type ? row.product_type : "inventory",
				};
			}).filter((row) => row.product != null && !row.id);
			saveData("po_line_items", items);
		},
		refreshProducts() {
			this.getRelatedProduct(this.supplier);
		},
		async getRPOLineItems() {
			try {
				const { items } = await RPOLineItems(this.$route.query?.rpuuid);
				this.line_items = items;
			} catch (error) {
				console.log(error);
			}
		},
	},
	computed: {
		...mapGetters(["localDB", "all_products"]),
		getSubTotal: () => {
			return function () {
				let sum = 0;
				this.line_items.forEach((element) => {
					sum = sum + Number(element.total);
				});
				this.sub_total = Number(sum);
				return this.sub_total;
			};
		},
		product_list: () => {
			return function (id) {
				let _productList = cloneDeep(this.productList);
				const newArray = filter(_productList, (row) => {
					if (row.value != id) {
						if (!find(this.line_items, (inner_row) => inner_row.product == row.id)) {
							return row;
						}
					} else {
						return row;
					}
				});
				return newArray;
			};
		},
	},
	components: {
		Draggable,
		TextInput,
		AutoCompleteInput,
		SelectUnit,
		Dialog,
		QuantityInput,
		PriceInput,
		ProductCreateDialog,
	},
	beforeDestroy() {
		EventBus.$off("reload:calculation");
	},
	async mounted() {
		const result = cloneDeep(this.value);
		this.line_items = result;
		this.$store.dispatch(SET_LINEITEMS);
		this.getAllProduct();
		const _tax_val = this.localDB("tax_value", 0);
		this.tax_value = _tax_val;
		if (this.$route.query?.rpuuid) {
			this.rpo_uuid = this.$route.query?.rpuuid;
			this.productList = this.all_products;
			this.getRelatedProduct(null);
		}
		if (this.$route.query.rpuuid) {
			await this.getRPOLineItems();
		}
		EventBus.$on("reload:calculation", (params) => {
			this.tax_value = params.tax_value;
			this.taxable_amount = params.taxable_amount;
			this.tax_amount = params.tax_amount;
			this.discount_value = params.discount_value;
			this.discount_value_type = params.discount_value_type;
			this.discount_amount = params.discount_amount;
			this.discount_type = params.discount_type;
			this.adjustment = params.adjustment;
			this.tax_applied = params.tax_applied ? true : false;
			this.adjustment_amount = params.adjustment;
			this.sub_total = params.sub_total;
			this.total = params.total;
		});
	},
};
</script>
<style scoped>
.po-line-items th {
	font-weight: 500;
}
.po-line-items th,
.po-line-items td:not(.borer-none) {
	border-bottom: 1px solid #c7c7c7;
	border-left: 1px solid #c7c7c7;
	height: 40px;
}
.po-line-items th:first-child,
.po-line-items td:first-child {
	border-left: none !important;
}
</style>
